<template>
  <!-- 联系我们 -->
  <div>
    <Footer></Footer>
    <div class="Dynamic">
      <div class="contact_wrap">
        <div class="inline-box">
          <div class="major">
            <div class="about-us">
              <div class="left-desc">
                <div class="introduce">
                  <div class="title">
                    <span class="ht">关于我们</span>
                    <!--<div class="summary">
                      <p>竞赛平台&实训基地</p>
                      <p>ICT类专业教学解决方案</p>
                    </div>-->
                    <div class="content">
                      <p>
                        数字经济产业作为全球经济增长与科技进步的新引擎，正悄然改变我国的产业结构和经济社会发展模式。以新基建之首的5G作为新一代信息技术发展的核心要素，将推动人才培养朝着模块化、数字化、虚拟化和智能化方向变革。中信科移动培训中心聚焦信息通信技术创新人才培养，匹配产业发展，培育多专业跨行业的复合型数字化人才。中信科移动培训中心拥有数十人的资深讲师团队与研发工程师团队，有着从教学方案设计到产教融合基地落地的全套人才储备，拥有同全国百余所高校的合作与建设经验。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-pht">
                <img src="@/assets/images/about-us.jpg" />
              </div>
            </div>
            <!--<div class="bedo">
              <div class="top">
                <div class="desc1">
                  <div class="td">
                    <h3 class="title1">我们能做什么？</h3>
                    <h3 class="title2">仿真实践&虚拟现实&全套教学解决方案</h3>
                  </div>
                  <div class="itp">
                    <p class="itp-title">
                      中信科移动培训中心依托丰富行业经验，以真实的产品研发与商业案例进行开发设计，冲破“孤岛”形态的课本理论、技术知识，通过仿真实践平台与AR\VR相结合，打造新一代电子信息与信息通信类教学模式。
                    </p>
                  </div>
                </div>
                <div class="desc1">
                  <div class="td">
                    <h3 class="title1"></h3>
                    <h3 class="title2">AI&车联网&实景沙盘</h3>
                  </div>
                  <div class="itp">
                    <p class="itp-title">
                      “产教融合”是信息通信时代人才培养的新模式。中信科移动拥有与全国百余所高校的合作经验，针对不同高校特点进行定制化开发与建设，融合AI技术与车联网技术、全息投影与实景沙盘等新的教学解决方案，满足不同层次的教学需求。
                    </p>
                  </div>
                </div>
              </div>

              <div class="bottom">
                <img src="@/assets/images/ipt.jpg" />
              </div>
            </div> -->
          </div>
          <div class="mainArea">
            <div class="tArea">
              <div class="title">
                <span class="result">我们能做什么</span>
              </div>
              <div class="direction">
                <div class="card">
                  <template v-for="(item, index) in dataList" :key='index'>
                    <div class="card1">
                      <div class="icon-box">
                        <span class="icon spt"></span>
                        <span class="line"></span>
                      </div>
                      <div class="btn-box">
                        <div class="itrs">
                          <span class="line">{{ item.title }}</span>
                        </div>
                        <p class="yh">
                          {{ item.desc }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="bottom">
                <img src="@/assets/images/ipt.jpg" />
              </div>

            </div>
            <div class="bArea">
              <div class="case">
                <div class="t_case">
                  <div class="left-desc">
                    <div class="introduce">
                      <div class="title">
                        <!--<span class="ht">产教融合经典案例</span>-->
                        <div class="summary">
                          <p>北京航空航天大学</p>
                          <p>空天电子信息国家级试验教学示范中心</p>
                          <p>空天电子信息国家级虚拟仿真实验教学中心</p>
                        </div>
                        <div class="content">
                          <p>
                            虚拟仿真实验教学中心拥有5G+应用创新教学平台、实践实训平台、仿真教学平台等多个新一代教学与竞赛平台，融合人工智能机器人、VR视频回传、全息影像、车联网沙盘、无人机等实践案例,打造“实验重器”与“竞赛中心”,创新实验教学模式,将新一代信息通信真实产品的设计研发流程带入课堂，将移动通信、大数据、人工智能等DOICT技术融入工程技术创新实践教学,践行“理实互通、虚实结合”的育人理念,努力实现专业教学与产业工程的深度融合。
                          </p>
                        </div>
                      </div>
                    </div>
                    

                  </div>
                  <div class="right-pht">
                    <img src="@/assets/images/more.jpg" />
                  </div>
                </div>

                <div class="b_case">
                  <div class="right-pht">
                    <img src="@/assets/images/more1.jpg" />
                  </div>
                  <div class="left-desc">
                    <div class="introduce">
                      <div class="title">
                        <!--<span class="ht">产教融合经典案例</span>-->
                        <div class="summary">
                          <p>武汉职业技术学院</p>
                          <p>5G+产教融合基地</p>
                        </div>
                        <div class="content">
                          <p>
                            5G+产教融合基地涵盖面广,技术密度高,基地整体设计"两室一厅一中心",由工程实训室,教学实验室,体验展厅与设备中心组成,并嵌入了虚拟仿真平台、DTTP教学平台、人工智能机器人平台、5G+智能网联汽车教学沙盘等前沿技术应用。平台间相互连通,将5G+大数据与人工智能融合,提供多模的验机脚本及自编程脚本测试环境,开放性的教学实践环境,实现定制化创新教学场景,并整合车联网、VR、人工智能算法、大数据，将理论与实践紧密结合,并可以根据授课以及展示需求,进行多种教学场景定制，集成虚拟仿真平台与教学电子幕墙,使教学、展示一体化。
                          </p>
                        </div>
                      </div>
                    </div>
                    

                  </div>
                </div>

                <div class="t_case">
                  <div class="left-desc">
                    <div class="introduce">
                      <div class="title">
                        <!--<span class="ht">产教融合经典案例</span>-->
                        <div class="summary">
                          <p>开放实验室</p>
                          <p>产学研联合培养基地</p>
                        </div>
                        <div class="content">
                          <p>
                            中信科移动联合福州物联网开放实验室，以5G移动通信技术为基础，共建“产学研联合培养基地”，实现集测试、教学、实践和观摩于一体的全功能5G基地建设。
                            “产学研联合培养基地”是集理论实操、业务演示、版本交付、场景复现等功能为一体的多功能教学平台。以ICT行业人才能力培养为主线，双方共同招生，秉承共创、共享、共建、共赢的合作理念，搭建多层次、多模块、多场景的人才培养平台，致力于加强通信技术理论实践、工程实践、实习实践、技能提升、“双师培训”和创新研究，并通过5G的前瞻性理论教研，结合实操训练来培养产业转型技术创新的综合型人才。
                          </p>
                        </div>
                      </div>
                    </div>
                    

                  </div>
                  <div class="right-pht" style="width:3.81rem">
                    <img src="@/assets/images/more2.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="advantage">
            <div class="advantage-show">
              <div class="title">
                <span>我们的优势</span>
              </div>
              <div class="card">
                <template v-for="(item, index) in advaList" :key="index">
                  <div class="list">
                    <div class="list-card">
                      <img :src="item.url" />
                    </div>
                    <div class="list-item2">
                      <span class="h_title">{{ item.title }}</span>
                    </div>
                    <div class="list-item3">
                      <p>{{ item.value }}</p>
                    </div>
                  </div>
                </template>
              </div>
              <div class="txt">
                <!--<div class="txt_box">
                  <div class="logo-nums">
                    <span class="num">100.000+</span>
                    <p class="add">累计服务师生数量</p>
                  </div>
                  <div class="logo-show">
                    <div class="show-item-ale">
                      <img src="../../assets/images/bfgd.png" />
                    </div>
                    <div class="show-item1">
                      <img src="../../assets/images/bjyd.png"/>
                    </div>
                    <div class="show-item1">
                      <img src="../../assets/images/whzy.png"/>
                    </div>
                    <div class="show-item1">
                      <img src="../../assets/images/bh.png"/>
                    </div>
                    
                  </div>
                </div>-->
              </div>
            </div>
          </div>
          <div class="serviceTeam">
            <div class="s-title">
              <span class="tw">
                服务团队&联系我们
              </span>
            </div>
            <div class="s-team-member">
              <div class="team-card">
                <template v-for="item in teamList">
                  <div class="team-card-list" :style="{opacity: item.name !=''?'1':'0'}">
                    <div class="team-card-desc">
                      <span class="card-title" style="font-weight: 600;">{{item.name}}</span>
                      <span class="card-title">{{item.phone}}</span>
                      <div class="area-center">
                        <span class="card-area">{{item.area}}</span>
                        <span class="card-area">{{item.area1}}</span>
                        <span class="card-area">{{item.area2}}</span>
                      </div>
                      
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </div>
          <div class="partner">
            <span class="title">合作伙伴</span>
          </div>
          <div class="custLogoArea">
            <div class="area-card">
              <template v-for="item in dxlgList">
                <div class="item">
                  <div class="top-logo">
                    <img :src="item.url" />
                  </div>
                  <div class="button-title">
                    <span class="s-name">
                          {{item.name}}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <h1>联系我们</h1> -->
    <Context />
    <Bottom></Bottom>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
// import Context from "./context.vue"
import $ from "jquery";

export default {
  name: 'Home',
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      lock: 0,
      dataList: [
        {
          title: '课程学习',
          desc: '作为国家人力资源和社会保障部和国家工业和信息化部的信息专业技术人才知识更新工程首批入选培训机构,致力于向国内外移动通信领域输送优秀人才,可提供以3G/4G/5G/6G全制式无线通信技术为主,涵盖的人工智能、大数据、物联网、智能制造、智能网联汽车等方向的学习课程。按需进行专业知识、工程实践、职业技能素养等课程解决方案定制化交付.'
        },
        {
          title: '师资培训',
          desc: '面向国内四大运营商、国际信息通信运营商、通信产业链上下游合作企业人员、高校教师和学生，面向基站运维、网络规划、网络优化、项目管理、研发科研、测试交付等不同岗位设置初级、中级、高级和专家级等不同级别的职业技能培训，制定详细的培训方案并给予人才发展咨询建议.'
        },
        {
          title: '工程师认证',
          desc: '重塑数字化人才认证模式,不仅聚焦基于职位要求的认证,同时重视对任职者的能力考核和人才发展规划。面向基站运维工程师、网络规划工程师、网络优化工程师、项目管理工程师、研发工程师、测试交付工程师等不同岗位,设置初级、中级、高级和专家级等不同级别的职业技能认证。建立数字化认证模式,提供基于仿真技术的模拟认证体系平台、基于人工智能技术的人才培养质量画像平台,创新人才认证新模式.'
        },
        {
          title: '教学资源与产品',
          desc: '教学平台学唐Online以视频、线上教材、考试及竞赛等多种形式支撑教学,实现了多元化互享方式。虚拟仿真教学平台,围绕信息通信技术导论、5G关键技术、5G系统工程、5G网络优化、5G+人工智能、5G+创新应实现教学。匹配教学型基站、智能网联汽车应用沙盘、人工智能机器人等教学产品,支撑科研创新和人才培养.'
        }
      ],
      advaList: [
        {
          title: '定制化开发',
          url: require('@/assets/images/c1.jpg'),
          value: '针对不同高校、专业与学科需求，对平台各个子模块进行定制化设计，将校园实景或教学特色融入平台中，做到身临其境的教学体验。'
        },
        {
          title: '工程师团队',
          url: require('@/assets/images/c2.jpg'),
          value: '拆解多个企业内部经典项目，把工程产品研发、设计、生产、决策等关键步骤，全周期全流程进行拆解融合，帮助学生掌握产品逻辑，形成工程思维，缩短从校园到岗位的距离。'
        },
        {
          title: '服务至上',
          url: require('@/assets/images/c3.jpg'),
          value: '中信科移动培训中心秉承“以先进的技术、优良的服务为客户创造价值”的宗旨，用创新专业的高品质服务和智能高效的工具，为高校师生提供最优秀的服务解决方案。'
        },
      ],
      teamList:[
        {
          name:'袁老师',
          phone:'186-0125-6072',
          area:'福建，海南，江苏，浙江，安徽，湖北，上海',
          area1:''
        },
        {
          name:'张老师',
          phone:'157-1294-9699',
          area:'山东，山西，陕西，河南，吉林，内蒙古，宁夏',
          area1:'青海，黑龙江'
        },
        {
          name:'李老师',
          phone:'133-9865-0452',
          area:'江西，湖南，广东，广西',
          area1:''
        },
        {
          name:'王老师',
          phone:'139-1012-1245',
          area:'云南，贵州，四川，重庆，新疆，西藏',
          area1:''
        },
        {
          name:'杜老师',
          phone:'136-0100-3450',
          area:'辽宁，河北，甘肃',
          area1:''
        },
        {
          name:'杜老师，张老师',
          phone:'136-0100-3450，157-1294-9699',
          area:' 北京，天津',
          area1:''
        },
      ],
      dxlgList:[
        {
          name: '北方工业大学',
          url: require('@/assets/images/dx1.jpg'),
        },
        {
          name: '北京航空航天大学',
          url: require('@/assets/images/dx2.jpg'),
        },
        {
          name: '北京科技大学',
          url: require('@/assets/images/dx3.jpg'),
        },
        {
          name: '北京邮电大学',
          url: require('@/assets/images/dx4.jpg'),
        },
        {
          name: '成都理工大学',
          url: require('@/assets/images/dx.jpg'),
        },
        {
          name: '电子科技大学',
          url: require('@/assets/images/dx5.jpg'),
        },
        {
          name: '东北大学',
          url: require('@/assets/images/dx6.png'),
        },
        {
          name: '东北林业大学',
          url: require('@/assets/images/dx7.jpg'),
        },
        {
          name: '福建商学院',
          url: require('@/assets/images/dx8.jpg'),
        },
        {
          name: '福建师范大学',
          url: require('@/assets/images/dx9.jpg'),
        },
        {
          name: '国防科技大学',
          url: require('@/assets/images/dx10.jpg'),
        },
        {
          name: '哈尔滨工业大学',
          url: require('@/assets/images/dx11.png'),
        },
        {
          name: '杭州电子科技大学',
          url: require('@/assets/images/dx12.jpg'),
        },
        {
          name: '华北电力大学',
          url: require('@/assets/images/dx13.jpg'),
        },
        {
          name: '江西应用科技学院',
          url: require('@/assets/images/dx14.png'),
        },
        {
          name: '兰州大学',
          url: require('@/assets/images/dx15.jpg'),
        },
        {
          name: '南京工业职业技术大学',
          url: require('@/assets/images/dx16.jpg'),
        },
        {
          name: '内蒙古工业大学',
          url: require('@/assets/images/dx17.jpg'),
        },
        {
          name: '青岛大学',
          url: require('@/assets/images/dx18.jpg'),
        },
        {
          name: '青岛科技大学',
          url: require('@/assets/images/dx19.jpg'),
        },
        {
          name: '山东科技大学',
          url: require('@/assets/images/dx20.jpg'),
        },
        {
          name: '武汉职业技术学院',
          url: require('@/assets/images/dx21.jpg'),
        },
        {
          name: '新疆大学',
          url: require('@/assets/images/dx22.jpg'),
        },
        {
          name: '云南大学',
          url: require('@/assets/images/dx23.jpg'),
        },
        {
          name: '云南经济管理学院',
          url: require('@/assets/images/dx24.jpg'),
        },
        {
          name: '长春理工大学',
          url: require('@/assets/images/dx25.png'),
        },
        {
          name: '长沙学院',
          url: require('@/assets/images/dx26.png'),
        },
        {
          name: '中山大学',
          url: require('@/assets/images/dx27.png'),
        },
        {
          name: '重庆电子工程职业学院',
          url: require('@/assets/images/dx28.png'),
        },
        {
          name: '重庆邮电大学',
          url: require('@/assets/images/dx29.png'),
        },
      ]
    }
  },
  methods: {

  },
}
</script>
  
<style scoped lang="scss" scope>
@import './Contact.scss';
@import './ContactArea.scss';
</style>